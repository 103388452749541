import { uuid } from '../../EditorDocum/entities/utils';

export const updateTagTd = (td: HTMLTableCellElement) => {
  if (td.textContent?.trim()) {
    td.querySelectorAll('p').forEach((el) => {
      if (!el.innerHTML?.trim()) {
        el.remove();
      }
    });

    let i = 1;
    for (const key in td.childNodes) {
      const el = td.childNodes[key];

      if (el && el.nodeValue?.trim() && el.nodeType === 3) {
        const p = document.createElement('p');
        p.id = uuid();
        p.innerHTML = el.nodeValue || '';
        el.replaceWith(p);
      } else if (i > 0 && !el.textContent?.trim() && el.nodeName) {
        td.removeChild(el);
      }

      i++;
    }
  } else {
    let isAdd = true;

    Object.values(td.children).forEach((el) => {
      if (!el.innerHTML?.trim()) {
        td.removeChild(el);
      } else {
        isAdd = false;
      }
    });

    if (isAdd) {
      const p = document.createElement('p');
      p.id = uuid();
      p.innerHTML = '<br>';
      td.appendChild(p);
    }
  }
};

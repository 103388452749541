export function replaceQuotes(html: string, nextSymbol = '') {
  let modifiedHtml = '';

  for (let i = 0; i < html.length; i++) {
    const currentChar = html[i];
    const prevChar = modifiedHtml[i - 1];
    const next = html[i + 1] || nextSymbol;

    if (currentChar === '"') {
      if (
        (!prevChar || [' ', '\n', '(', '>', ';', '.', ','].includes(prevChar)) &&
        ![' ', '<', '»', '\n', '.', ';', ','].includes(next)
      ) {
        modifiedHtml += '«';
        continue;
      } else {
        modifiedHtml += '»';
        continue;
      }
    }

    if (currentChar === '“') {
      modifiedHtml += '«';
      continue;
    }
    if (currentChar === '”') {
      modifiedHtml += '»';
      continue;
    }

    modifiedHtml += currentChar;
  }
  return modifiedHtml;
}
